import * as React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Social from "../components/social.js"
import Layout from "../components/LayoutAlt.js"
import { FaPaperPlane } from "react-icons/fa";


const Thankyou = ({ data }) => {
    return(
        <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="We are grateful for reaching out to us." />
        <title>ReaCH | Thank You</title>
        <link rel="canonical" href="https://reachun.org/thankyou" />
      </Helmet>

        <div className="sm:w-3/4 sm:ml-1/8 mt-14 sm:mt-20 lg:mt-32 xs:p-4 sm:p-0">
        <h1 className="font-playfairdisplay font-bold xs:text-4xl sm:text-6xl text-blue-dark text-center border-b border-gray-300 pb-12 mb-12">{data.strapiThankyou.title}</h1>
            <section  className="flex">
                <div>
                <p className="font-opensans text-gray-700 text-xl tracking-wider">{data.strapiThankyou.content}</p>
                </div>
            </section>
            <section className="mt-24 mb-24 text-center">
                    <Link to={data.strapiThankyou.calltoaction.path}>
                    <button className="h-16 w-64 bg-orange-dark rounded-xl">
                    <div className="flex justify-center items-center">
                    <p className="pr-4 text-white"><FaPaperPlane size="24" /></p>
                    <p className="font-opensans text-lg text-white">{data.strapiThankyou.calltoaction.title}</p>
                    </div>
                   </button>
                    </Link>
            </section>
        </div>
           
        <Social />
        </Layout>
    )
}
export const query = graphql`
    query ThankyouPageQuery {
        strapiThankyou {
            image {
              url
            }
            title
            content
            calltoaction {
              id
              title
              path
              icon
            }
          }
    }
`
export default Thankyou